<template>
  <b-performance-table
    :data="rows"
    :custom-headers="headers"
    :loading="loading"
    :show-diff="dates.compareWith"
    :data-grouped-by="groupBy"
  />
</template>

<script>
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';
import performanceDataMixin from '~/components/mixins/performance-data-mixin';

export default {
  name: 'DashDetailAdGroupsPerformanceList',
  components: {
    BPerformanceTable: () =>
      import(
        /* webpackChunkName: "b-performance-table" */ '~/components/elements/b-performance-table.vue'
      ),
  },

  mixins: [performanceDataMixin],

  data() {
    return {
      loading: false,
      performance: null,
      groupBy: 'adgroup',
    };
  },
  computed: {
    headers() {
      return [{ name: 'Name', sortKey: 'name' }, this.getCommonHeader()];
    },
    rows() {
      if (!this.performance) {
        return [];
      }

      const rows = this.performance.map((item) => {
        const rowItem = this.transformToRowItem(item);
        rowItem.name = item.name;
        return rowItem;
      });

      return this.sortRows(rows);
    },
  },
  async mounted() {
    try {
      await this.loadPerformanceData(this.payload);
    } catch (err) {
      console.error('error mounting DashDetailAdGroupsPerformanceList ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },
  methods: {
    async loadPerformanceData(payload) {
      this.loading = true;
      payload = { ...payload };
      payload.groupBy = this.groupBy;

      try {
        const dataValidated = this.validateParams(payload, this.advertiser);
        let result = [];
        if (dataValidated) {
          result = await advertiserReportsAPI.performance(
            this.advertiser.id,
            buildQueryString(payload)
          );
        }
        this.performance = result;
      } catch (err) {
        console.error('error fetching AdGroups Performance ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .no-bg.zero {
  margin-top: 0 !important;
  margin-left: -10px !important;
}
</style>
